.main-menu {
  width: 100%;
  .navbar-collapse {
    background-color: white;
    box-shadow: 0 15px 20px -5px rgba(4, 26, 90, 0.35);
    position: relative;
    z-index: 10;
  }
  .navbar-toggler {
    border: none;
    border-radius: 0;
    padding: 0;
    .navbar-toggler-icon {
      width: 2em;
      height: 2em;
      content: "";
      background: repeating-linear-gradient(
          to bottom,
          #4D9BE8,
          #4D9BE8 20%,
          #ffffff 20%,
          #ffffff 40%
      );
    }
  }
}

@media (min-width: 992px) {
  .main-menu {
    .navbar-collapse {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
