.avatar {
  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
  }
}

.user-info {
  color: white;
  padding-left: 5px;
  .user-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .user-role {
    font-size: 0.86rem;
  }
}