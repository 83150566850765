$padding-top: 70px;
$padding-bottom: 40px;

.header {
  height: 160px;
}

.content-header {
  padding-top: $padding-top;
  padding-bottom: $padding-bottom;
}

.actions-header {
  padding-top: $padding-top;
  padding-bottom: $padding-bottom;
}

.auth-header {
  box-shadow: 0 15px 20px 0 rgba(1, 1, 1, 0.25);
}