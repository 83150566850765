.main-menu {
  .main-menu__items {
    .main-menu-item {
      padding: 10px 15px;
      color: #1E1832;
      &.main-menu-item_active {
        background-color: #4D9BE8;
        color: white;
        .main-menu-item__count {
          background: #fff;
          color: #000;
        }
      }
      .main-menu-item__count {
        background: #4D9BE8;
        color: #fff;
        border-radius: 100%;
        padding: 0 6px 1px 6px;
        line-height: 1;
        margin-left: 7px;
      }
    }
  }
}