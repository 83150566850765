$outer-padding-xxl: 120px;
$inner-padding-xxl: 40px;
$outer-padding: 40px;
$inner-padding: 40px;

$outline-color: white;
$outline-color-hover: #f7f7f7;
$primary-color: #4d9be8;
$primary-color-hover: #308ce7;
$success-color: #b8efd6;
$success-color-hover: #9ef1cb;
$btn-disabled-color: #686868;
$btn-disabled-color-hover: #dedede;

html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
#root>.container-fluid,
#root>.container-fluid>.main {
  height: 100%;
  min-height: 100%;
}

html {
  font: normal 14px/1 Mulish, Arial, sans-serif;
}

body {
  color: #1e1832;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 2.29rem;
}

h2 {
  font-size: 1.72rem;
}

p {
  line-height: 1.3;
}

// Temporary "only desktop" mode for layout
.main {
  &.auth {
    min-width: 1200px;
  }
  &.guest {
    min-width: 992px;
  }
}

.react-hint {
  .react-hint__content {
    background-color: #56a0ea;
    color: #fff;
    font-weight: bold;
    padding: 15px;
  }

  &.react-hint--top:after {
    border-top-color: #56a0ea;
  }

  &.react-hint--left:after {
    border-left-color: #56a0ea;
  }

  &.react-hint--right:after {
    border-right-color: #56a0ea;
  }

  &.react-hint--bottom:after {
    border-bottom-color: #56a0ea;
  }
}

/**
 * @deprecated Do not use this global class. Follow the component paradigm
 */
.mt-30px {
  margin-top: 30px;
}

.content-side {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  
  .content-header {
    padding-left: $outer-padding;
    padding-right: $inner-padding;
  }
  .content-guest {
    padding: 40px $inner-padding 20px $outer-padding;
    a {
      color: #4d9be8;
      font-weight: normal;
    }
  }
  .content-auth {
    padding: 80px $inner-padding $inner-padding $outer-padding;
  }
  .footer {
    padding: 20px $inner-padding 20px $outer-padding;
    margin-top: auto;
    
    &.footer-auth{
      border-top: 1px solid #9999C9;
      padding-top: 56px;
      padding-bottom: 50px;
    }
    a {
      color: #4d9be8;
      font-weight: normal;
    }
  }
}

.actions-side {
  background-color: $primary-color;
  .actions-header {
    padding-left: $inner-padding;
    padding-right: $outer-padding;
    margin-left: -15px;
    margin-right: -15px;
    .content {
      max-width: 100%;
      //padding: 40px;
      width: 420px;
      margin: 0 auto;
    }
  }
  .actions-guest,
  .actions-auth {
    padding-left: $inner-padding;
    padding-right: $outer-padding;
    .actions-form {
      background-color: white;
      border-radius: 15px;
      box-shadow: 0 20px 40px -20px rgba(1, 1, 1, 0.5);
      max-width: 100%;
      padding: 40px;
      width: 420px;
      a {
        color: #4d9be8;
        font-weight: normal;
      }
      h1,
      h2 {
        font-weight: bold;
        font-size: 1.86rem;
        margin-bottom: 40px;
        line-height: 1.23em;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  .actions-guest {
    .actions-form {
      margin: 40px auto 40px;
    }
  }
  .actions-auth {
    .actions-form {
      margin: 80px auto 40px;
    }
  }
}

/**
 * @deprecated Do not use this global class. Follow the component paradigm
 */
.flex-block {
  display: flex;
  margin: 0 -15px 0;
  .flex-item {
    padding: 0 15px 0;
  }
}

/**
 * @deprecated Do not use this global class. Follow the component paradigm
 */
.justify-content-between {
  -ms-flex-pack:justify!important;
  justify-content:space-between!important;
}

.effect-3d {
  border: 1px solid #F4F9FD;
  border-radius: 10px;
  box-shadow: 0 15px 20px -5px rgba(4, 26, 90, 0.35);
}

/* Rewrite Bootstrap properties */
a {
  color: #1e1832;
  font-weight: bold;
  opacity: 1;
  text-decoration: underline;
  &.btn,
  &.nav-link {
    text-decoration: none;
  }
  &:hover {
    color: #4d9be8;
  }
  &.nav-link {
    font-weight: normal;
  }
}
#root {
  .btn-block + .btn-block {
    margin-top: 25px;
  }
  .btn {
    border-radius: 7px;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;
    padding: 16px 20px;
    line-height: 1em;
    &.btn-primary,
    &.btn-outline-primary {
      border: solid 1px $primary-color;
    }
    &.btn-primary {
      background-color: $primary-color;
      color: white;
      &:hover {
        background-color: $primary-color-hover;
        border: solid 1px $primary-color-hover;
      }
      a {
        color: white;
      }
      &:disabled {
        background-color: $btn-disabled-color !important;
        border: solid 1px $btn-disabled-color !important;
      }
    }
    &.btn-outline-primary {
      background-color: $outline-color;
      color: $primary-color;
      &:hover {
        background-color: $outline-color-hover;
        border: solid 1px $primary-color;
      }
      a {
        color: $primary-color;
      }
      &:disabled {
        color: $btn-disabled-color !important;
        border: solid 1px $btn-disabled-color !important;
      }
    }
    &.btn-success,
    &.btn-outline-success {
      border: solid 1px $success-color;
    }
    &.btn-success {
      background-color: $success-color;
      color: white;
      &:hover {
        background-color: $success-color-hover;
        border: solid 1px $success-color-hover;
      }
      a {
        color: white;
      }
      &:disabled {
        background-color: $btn-disabled-color !important;
        border: solid 1px $btn-disabled-color !important;
      }
    }
    &.btn-outline-success {
      background-color: $outline-color;
      color: $success-color;
      &:hover {
        background-color: $outline-color-hover;
        border: solid 1px $success-color;
      }
      a {
        color: $success-color;
      }
      &:disabled {
        color: $btn-disabled-color !important;
        border: solid 1px $btn-disabled-color !important;
      }
    }
    &.disabled,
    &.disabled:hover {
      background-color: #f7f7f7;
      border: solid 1px #f7f7f7;
      color: #686868;
      cursor: default;
      opacity: 1;
    }
    a {
      text-decoration: none;
    }
  }
}

// TODO: move this to the component definition
dl, ol, ul {
  padding-inline-start: 25px;
  list-style-position: outside;
  li {
    line-height: 1.3;
    margin-bottom: 1em;
    .li-content-nopadding {
      margin-left: -25px;
    }
  }
}

// TODO: move this to the component definition
ol {
  list-style-type: none;
  counter-reset: num;
  padding-inline-start: 0;
  & > li {
    padding-left: 25px;
    &:before {
      content: counter(num) '.';
      counter-increment: num;
      display: inline-block;
      margin-left: -25px;
      width: 25px;
    }
  }
}

@media (min-width: 1500px) {
  .content-side {
    .content-header,
    .content-guest,
    .content-auth,
    .footer {
      padding-left: $outer-padding-xxl;
      padding-right: $inner-padding-xxl;
    }
  }
  .actions-side {
    .actions-header,
    .actions-guest,
    .actions-auth {
      padding-left: $inner-padding-xxl;
      padding-right: $outer-padding-xxl;
    }
  }
}
