.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  
  .loader-logo {
    position: relative;
    width: 150px;
    height: 41px;
    background: url('../logo/logo.svg') top left/contain no-repeat;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 45px;
      top: -3px;
      width: 14px;
      height: 14px;
      background: url('star.svg') top left/contain no-repeat;
      animation: rotation 5s linear infinite;
    }
  }
  .label {
    font-size: 0.8rem;
    margin-top: 5px;
  }
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
