.flash-messages {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
  font-size: 1rem;
  .flash-message {
    border: 1px solid #F4F9FD;
    border-radius: 7px;
    box-shadow: 0 15px 20px -5px rgb(4 26 90 / 35%);
    padding: 20px 30px 5px 20px;
    margin: 20px;
    background: #fff;
    position: relative;
    .block-header {
      margin-bottom: 15px;
    }
    &.flash-message-info {
      background: #7ab4ee;
      border-color: #4d9be8;
      &, & * {
        color: white;
      }
    }
    &.flash-message-error {
      background: #FFEAEA;
      border-color: #F7BDBD;
      &, & * {
        color: #bf3737;
      }
    }
    .flash-message-btn-close {
      display: block;
      width: 17px;
      height: 17px;
      font-size: 0;
      background: url("cross.svg") center center no-repeat;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      &:hover {

      }
    }
  }
}