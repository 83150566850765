.block-header {
  margin-bottom: 30px;
  .title {
    color: #686868;
    font-weight: bold;
  }
  .action-buttons {
    margin: 0 -5px;
    .action-button {
      padding: 0 5px;
      img {
        height: 17px;
      }
    }
  }
}
